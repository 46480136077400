<template>
  <div>

    <v-card-text>
      <div v-if="items.length <= 0">
        <v-alert text type="info">No hay data para mostrar</v-alert>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" sm="3">
            <v-card-text class="mb-2 pa-2 white">
              <v-list>
                <v-list-item-group v-model="selected" color="primary">
                  <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content>
                      <v-list-item-title>{{
    item.Telefono | phone
}}</v-list-item-title>
                      <v-list-item-subtitle>{{
    item.ProductName
}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div v-if="currentUser.corte.BCompletado">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="info" dark v-bind="attrs" v-on="on">
                              circle
                            </v-icon>
                          </template>
                          <span>Pagadas</span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on">
                              circle
                            </v-icon>
                          </template>
                          <span>Antes del corte</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <p class="subtitle-1 px-4 py-2">
                <b> Total puntos:</b> {{ puntosSum }}
              </p>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="9">
            <v-card outlined class="mb-2 pa-2 white">
              <v-card-title style="font-size: 15px !important">
                <span class="mr-10" style="font-weight: bold">{{
    item.Telefono | phone
}}</span>
                <span style="color: #e4322b" class="mr-10" v-if="item.FechaCancelacion">Cancelado</span>
                <span style="color: #a6ce39" class="mr-10" v-else>Activo</span>
                <span class="mr-10">{{ item.TipoProducto }}</span>
                <span class="mr-10">Fecha activacion: {{ item.FechaActivacion | date }}</span>
                <v-spacer></v-spacer>
                <crea-reclamacion-modal :activationId="item.ActivationID" :telefono="item.Telefono"
                  :productCode="item.ProductCode" :fechaActivacion="item.FechaActivacion" :numeroOs="item.NumeroOS"
                  :productoTipoId="item.ProductoTipoID" :productId="item.ProductID"
                  sistema="Interno"></crea-reclamacion-modal>

              </v-card-title>
            </v-card>

            <v-card outlined class="mb-2 pa-2 white">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <span>Tarjeta</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{ item.Codigo }}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Producto</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
    item.ProductName
}}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Sub segmento</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
    item.SubSegmento
}}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Serie</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{ item.Serie }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3">
                    <span>Fecha movimiento</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
    item.FechaMovimiento | date
}}</span>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span>Fecha cancelación</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
    item.FechaCancelacion | date
}}</span>
                  </v-col>

                  <v-col v-for="atributo in item.Atributos" :key="atributo.Atributo" cols="12" sm="3">
                    <span>{{ atributo.Atributo }}</span>
                    <br />
                    <span class="font-weight-bold pr-1">{{
    atributo.Valor
}}</span>
                  </v-col>

                  <v-col cols="12" sm="3" v-if="item.bMotivoNoPago">
                    <span class="font-weight-bold pr-1" style="color: #e4322b">Motivo no pago ({{ item.Motivo
}})</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card outlined class="mb-2 pa-2 white">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-tabs>
                      <v-tabs-slider color="primary"></v-tabs-slider>
                      <v-tab href="#aplicados">Incentivos</v-tab>
                      <v-tab href="#motivoNopago">Motivo no pago</v-tab>
                      <v-tab-item value="aplicados">
                        <br />

                        <v-data-table :headers="headerIncentivo" :items="incentivos" :expanded.sync="expanded"
                          show-expand single-expand loading-text="Consultando... favor espere" item-key="Codigo">
                          <template v-slot:item.data-table-expand="{ expand, isExpanded }">
                            <v-icon @click="expand(!isExpanded)">{{
    isExpanded ? "keyboard_arrow_down" : "keyboard_arrow_up"
}}</v-icon>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" style="background: #f2f2f2; padding: 5px">
                              <v-tabs>
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab href="#empleados">EMPLEADOS</v-tab>
                                <v-tab-item value="empleados">
                                  <div class="pa-4" style="background: #fff !important">
                                    <v-data-table :headers="headerEmpleados" :items="item.Empleados">
                                    </v-data-table>
                                  </div>
                                </v-tab-item>
                              </v-tabs>
                            </td>
                          </template>
                        </v-data-table>

                      </v-tab-item>
                      <v-tab-item value="motivoNopago">
                        <br />
                        <v-data-table :headers="headerMotivoNoPago" :items="LineasMotivoNoPago"
                          item-key="Codigo">

                        </v-data-table>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-fab-transition>
      <v-btn v-if="$vuetify.breakpoint.mobile" color="transparent" absolute top right fixed
        style="top: 120px; right: 0px" @click.native="drawerRight = !drawerRight">
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-btn-toggle v-model="tipo" borderless dense group color="info" class="d-flex flex-row">
          <v-btn value="individual" text>individual</v-btn>
          <v-btn value="masiva" text>Masiva</v-btn>
          <v-btn value="archivo" text>Archivo</v-btn>
        </v-btn-toggle>
        <div v-if="tipo == 'individual'">
          <v-text-field v-model="search" clearable hide-details
            placeholder="Teléfono, Número de Orden, Serie (IMEI), Simcard" v-on:keyup.13="get" :rules="rules.search"
            ref="search" :loading="loading" @click:append="get"></v-text-field>
          <br />
          <div class="mb-5 text-right">
            <v-btn block depressed color="primary" @click="get">
              <v-icon left>search</v-icon>Buscar
              <span slot="loader">Buscando...</span>
            </v-btn>
          </div>
        </div>
        <div v-if="tipo == 'masiva'">
          <v-row wrap>
            <v-col cols="12" sm="12" class="d-flex">
              <v-autocomplete :items="sucursalesVisibles" v-model="sucursalesSelected" item-text="CodNombre"
                item-value="CanalSucursalId" label="Sucursales" color="red" append-icon="keyboard_arrow_down"
                hint="Dejar vacio para filtrar por todas" multiple chips deletable-chips persistent-hint clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" class="d-flex">

              <v-checkbox label="Solo sucursales activas" v-model="soloActivas" color="red"
                style="margin-top: 0px; height: 20px"></v-checkbox>
            </v-col>
            <br />
            <v-col cols="12" sm="12" class="d-flex">

              <v-autocomplete :items="tipoProducto" v-model="tipoproductosSelected" item-text="ProductoName"
                item-value="ProductoTipoId" label="Tipos productos" hint="Dejar vacio para filtrar por todos"
                append-icon="keyboard_arrow_down" multiple chips deletable-chips persistent-hint clearable>
              </v-autocomplete>
            </v-col>
            <br />
            <v-col cols="12" sm="12" class="d-flex">

              <v-autocomplete :items="listProductos" :filter="customFilter" v-model="productosSelected"
                item-text="ProductName" item-value="ProductId" label="Productos"
                hint="Dejar vacio para filtrar por todos" append-icon="keyboard_arrow_down" multiple chips
                deletable-chips persistent-hint clearable></v-autocomplete>
            </v-col>
            <br />
            <v-col cols="12" sm="12" class="d-flex">

              <date-selector label="Fecha desde" :fecha.sync="fechaInicio" :rules="dateRule"></date-selector>

            </v-col>
            <br />
            <v-col cols="12" sm="12" class="d-flex">

              <date-selector label="Fecha hasta" :fecha.sync="fechaFin" :rules="dateRule"></date-selector>
            </v-col>

            <br />
          </v-row>

          <br />
          <div class="mb-5 text-right">
            <v-btn block depressed color="primary" :disabled="disabled" @click="get">
              <v-icon left>search</v-icon>Buscar
              <span slot="loader">Buscando...</span>
            </v-btn>
          </div>
          >
        </div>
        <div v-if="tipo == 'archivo'">
          <v-row>
            <v-col cols="12" sm="12" class="d-flex">
              <v-file-input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" counter
                v-model="file" chips placeholder="Seleccionar archivo" :show-size="1000" :rules="rules.file"
                style="padding: 0px"></v-file-input>
            </v-col>
            <v-col cols="12" sm="12" class="d-flex">
              <a target="_blank" href="static/BusquedaMasiva.xlsx">Descargar plantilla</a>
            </v-col>
            <br />
          </v-row>
          <br />
          <div class="mb-5 text-right">
            <v-btn block depressed color="primary" :disabled="disabled" @click="get">
              <v-icon left>search</v-icon>Buscar
              <span slot="loader">Buscando...</span>
            </v-btn>
          </div>
        </div>
        <br />
      </v-card-text>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import CreaReclamacionModal from "@/components/reclamacion/reclamacion_creacion";
import apiActivacion from "@/api/activaciones";
import apiComision from "@/api/comisiones";
import apiAsociado from "@/api/asociados";
import apiProducto from "@/api/productos";
import helper from "@/filters/helper";
import download from "downloadjs";

export default {
  components: {
    CreaReclamacionModal,
  },
  data() {
    return {
      drawerRight: true,
      disabled: false,
      tipo: "individual",
      tab: "consulta",
      filter: true,
      loading: false,
      soloActivas: true,
      search: "",
      selected: null,
      file: [],
      item: null,
      items: [],
      incentivos: [],
      LineasMotivoNoPago:[],
      expanded: [],
      sucursalesSelected: [],
      tipoproductosSelected: [],
      listProductos: [],
      sucursales: [],
      productosSelected: [],
      fechaInicio: null,
      fechaFin: null,
      dateRule: [(v) => !!v || "Seleccionar una fecha"],
      headerIncentivo: [
        { text: "Código", value: "Codigo" },
        { text: "Incentivo", value: "Incentivo" },
        { text: "Tipo Calculo", value: "AgrupacionDisplay" },
        { text: "Resultado", value: "Resultado" }
      ],
      headerEmpleados: [
        { text: "Tarjeta", value: "CodigoTarjeta" },
        { text: "Nombre", value: "TarjetaDisplay" },
        { text: "Monto", value: "Monto" },
      ],
      headerMotivoNoPago: [
        { text: "Código", value: "Codigo" },
        { text: "Incentivo", value: "Incentivo" },
        { text: "Tipo Calculo", value: "AgrupacionDisplay" },
        { text: "MotivoCode", value: "MotivoCode" }
      ],

      customFilter(item, queryText) {
        const hasValue = (val) => (val != null ? val : "");
        const text =
          hasValue(item.CodNombre) ||
          hasValue(item.ProductoName) ||
          hasValue(item.ProductName) ||
          hasValue(item.ProductCode) ||
          hasValue(item.column);
        const query = hasValue(queryText);
        return (
          text
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        );
      },
      rules: {
        search: [(v) => !!v || "Debe digitar un criterio de busqueda"],
        file: [
          (v) =>
            (v && v.size < 4 * 1000 * 1000) ||
            "El archivo que esta intentando subir excede los 4 MB",
        ],
      },
      breadcrumb: [
        {
          text: "Activación",
          disabled: true,
        },
      ],
      prueba: null,
    };
  },
  computed: {
    tipoProducto() {
      return this.$store.getters.tipoProducto;
    },
    listSegmento() {
      return this.currentUser.segmento;
    },
    listFamilia() {
      return this.$store.getters.tipoProducto;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    puntosSum() {
      if (this.items.length > 0) {
        var atributos;
        var total = 0;

        atributos = this.items.map((c) => c.Atributos);
        atributos.forEach((element) => {
          element.forEach((item) => {
            if (item.Atributo == "Puntos") total += parseFloat(item.Valor);
          });
        });

        return total;
      } else return 0;
    },
    sucursalesVisibles() {
      if (this.soloActivas) {
        return this.sucursales.filter(
          (sucursal) => sucursal.BEnable && !sucursal.EndDate
        );
      } else {
        return this.sucursales;
      }
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    get() {
      this.items = [];
      this.loading = true;
      var canales = [];
      try {
        canales = [
          ...new Set(
            this.currentUser.seleccion.gestionados.map((c) => c.CanalID)
          ),
        ];
      } catch {
        console.log("next");
      }

      canales.push(this.currentUser.canalid);

      if (this.tipo === "individual") {
        let corte = this.currentUser.corte;

        var param = {
          corte,
          empleado: Array.from(
            this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
          ),
          gestionado: Array.from(
            this.currentUser.seleccion.gestionados.map((c) => c.CanalSucursalId)
          ),
          canal: Array.from(canales),
          referencia: this.search,
        };
        apiActivacion
          .getActivacionIndividual(param)
          .then((response) => {
            this.items = response.data;
            if (this.items.length > 0) {

              this.selected = 0;

              this.item = this.items[0];
              this.getDetail(this.item);

            } else {
              this.setMessage(
                "No se encontró resultado para la consulta realizada"
              );
            }

            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });

      }
      else if (this.tipo === "masiva") {

        let sucursalesIds = this.sucursales.map(a => a.CanalSucursalId);

        if (this.sucursalesSelected.length > 0) {
          sucursalesIds = this.sucursalesSelected;
        }

        let param = new FormData();

        param.append("corteId", this.currentUser.corteId);
        param.append("startDate", this.fechaInicio);
        param.append("endDate", this.fechaFin);

        param.append(
          "sucursales",
          Array.from(sucursalesIds)
        );

        param.append(
          "tiposProductos",
          Array.from(this.tipoproductosSelected)
        );

        param.append(
          "productos",
          Array.from(this.productosSelected)
        );

        param.append(
          "empleados",
          Array.from(
            this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
          )
        );
        param.append(
          "gestionados",
          Array.from(
            this.currentUser.seleccion.gestionados.map((c) => c.CanalSucursalId)
          )
        );

        apiActivacion.getActivacionMasivaParametros(param).then((response) => {
          download(response.data, "resultado" + ".xlsx");
          this.loading = false;
        });
      }
      else {

        let param = new FormData();

        param.append("corteId", this.currentUser.corteId);

        param.append(
          "empleados",
          Array.from(
            this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
          )
        );
        param.append(
          "gestionados",
          Array.from(
            this.currentUser.seleccion.gestionados.map((c) => c.CanalSucursalId)
          )
        );
        param.append("file", this.file);
        apiActivacion.getActivacionMasiva(param).then((response) => {
          download(response.data, "resultado" + ".xlsx");
          this.loading = false;
        });
      }
    },
    getDetail(item) {
      var param = {
        activacionID: item.ActivationID,
        empleado: Array.from(
          this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId)
        ),
      };

      apiComision.getComisionActivacionEmpleado(param).then((result) => {
        this.incentivos = result.data;
      });

      apiComision.getLineasNoPagadasActivacionEmpleado(param).then((result) => {
        this.LineasMotivoNoPago = result.data;
      });
    },
    getTipoCalculo(value) {
      console.log(helper.tipoCalculo)
      return helper.tipoCalculo.find((c) => (c.value = value)).text;
    },
    async getSucursales() {

      const empleados = this.currentUser.seleccion.empleados.map((c) => c.EmpleadosId);
      empleados.push(this.$store.getters.user.empleadoid)

      var param = {
        empleado: Array.from(
          empleados
        )
      };

      const response = await apiAsociado.getSucursalesByEmpleados(param);

      this.sucursales = response.data;
    },
    refreshProductos() {
      apiProducto
        .GetProductosByProductoTipoID(this.tipoproductosSelected)
        .then((response) => {
          this.listProductos = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
    setFechas() {
      let startDate = null;
      let endDate = null;

      if (this.$store.getters.user.corte) {
        startDate = this.$store.getters.user.corte.StartDate.toString();

        if (startDate.includes("T")) {
          startDate = startDate.split('T')[0]
        }

        endDate = this.$store.getters.user.corte.EndDate.toString();

        if (endDate.includes("T")) {
          endDate = endDate.split('T')[0]
        }
      }

      this.fechaInicio = startDate;
      this.fechaFin = endDate;
    }
  },
  watch: {
    tipoproductosSelected: function () {
      this.refreshProductos();
    },
    selected: function () {
      if (this.selected != undefined && this.items.length > 0) {
        this.item = this.items[this.selected];
        this.getDetail(this.item);
      } else {
        this.item = null;
      }
    },
    '$store.state.updateCorte': function () {
      this.setFechas();
      this.get();
    }
  },
  mounted() {


    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    if (this.currentUser.seleccion == undefined) {
      this.disabled = true;
      this.setMessage(
        "Primero debe seleccionar el corte para navegar en el sistema"
      );
    } else {
      this.disabled = false;
    }

    this.getSucursales();
    this.refreshProductos();
    this.setFechas();
  },
};
</script>
<style>
tr.expanded.expanded__content {
  box-shadow: none !important;
}

.v-navigation-drawer {

  width: 280px !important;

}

tr.expanded.expanded__content {
  box-shadow: none !important;
}
</style>